import React, { useState, useEffect, useRef } from "react";
import { m } from "framer-motion";
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";
import useSolutionsData from "@staticQueries/SolutionsQuery";
import { SolutionChip } from "@molecules";
import { Container } from "@atoms";
import { AppLink } from "@base";

const SolutionsWheel = ({ label }) => {
  const solutions = useSolutionsData();

  const [hovered, setHovered] = useState(null);
  const [radius, setRadius] = useState(0);
  const circle = useRef(null);

  const { innerWidth } = useWindowSize();

  useEffect(() => {
    if (circle.current) {
      setRadius(circle.current.clientWidth / 2);
    }
  }, [innerWidth, circle.current]);

  const bgColor = [
    "bg-teal-dark",
    "bg-black",
    "bg-purple-dark",
    "bg-purple",
    "bg-pink",
    "bg-teal",
    "bg-teal-light",
    "bg-teal-lighter",
    "bg-teal-lightest",
  ];

  const isDark = [
    "bg-teal-dark",
    "bg-pink",
    "bg-pink",
    "bg-purple",
    "bg-purple-dark",
    "bg-black",
  ];

  const variants = {
    show: i => ({
      scale: hovered === i ? 1.2 : 1,
      opacity: 1,
      x:
        i < 5
          ? Math.cos(
              ((-i - solutions.length / 4) / solutions.length) * 2 * Math.PI
            ) * radius
          : Math.cos(
              ((i - 4 - solutions.length / 4) / solutions.length) * 2 * Math.PI
            ) * radius,
      y:
        i < 5
          ? Math.sin(
              ((-i - solutions.length / 4) / solutions.length) * 2 * Math.PI
            ) * radius
          : Math.sin(
              ((i - 4 - solutions.length / 4) / solutions.length) * 2 * Math.PI
            ) * radius,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    }),
    hide: {
      scale: 0,
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <div className="flex flex-row flex-wrap md:items-center md:gap-10">
        {!!solutions?.length && (
          <div className="order-2 mt-10 flex flex-grow flex-col gap-5 md:mt-0 md:flex-1 lg:order-1">
            {solutions.slice(0, 5).map((solution, i) => {
              return (
                <m.div
                  onHoverStart={() => setHovered(i)}
                  onHoverEnd={() => setHovered(null)}
                  className="md:-mx-3"
                >
                  <SolutionChip
                    key={`${solution.uid}-chip`}
                    color={bgColor[i]}
                    hovered={hovered}
                    order={i}
                    {...solution}
                  />
                </m.div>
              );
            })}
          </div>
        )}
        <div className="relative order-1 mt-10 flex w-full items-center justify-center md:p-3 lg:order-2 lg:my-0 lg:w-1/3 lg:flex-shrink-0">
          <ul
            ref={circle}
            className="relative z-10 aspect-square h-auto w-[75%] max-w-[24rem] sm:w-full lg:max-w-none"
          >
            {/* center circle */}
            <m.li
              initial={{
                scale: 0,
                opacity: 0,
                x: radius,
                y: radius,
                transition: {
                  type: "tween",
                  duration: 0.5,
                },
              }}
              animate={{
                scale: 1,
                opacity: 1,
                x: radius,
                y: radius,
                transition: {
                  type: "tween",
                  duration: 0.5,
                },
              }}
              className="absolute inset-[calc(0-1.75rem)] flex flex h-[3.5rem] w-[3.5rem] origin-center select-none items-center justify-center rounded-full bg-teal-dark font-heading text-sm font-bold text-white sm:inset-[calc(0-2.25rem)] sm:h-[4.5rem] sm:w-[4.5rem] sm:text-base"
            >
              {label || "Data"}
            </m.li>
            {/* the icons */}
            {solutions.map((solution, ii) => {
              return (
                <li className="absolute inset-[calc(50%-1.75rem)] h-[3.5rem] w-[3.5rem] sm:inset-[calc(50%-2.25rem)] sm:h-[4.5rem] sm:w-[4.5rem]">
                  <AppLink to={solution.url}>
                    <m.div
                      key={solution.uid}
                      custom={ii}
                      initial="hide"
                      whileInView="show"
                      variants={variants}
                      onHoverStart={() => setHovered(ii)}
                      onHoverEnd={() => setHovered(null)}
                      className={classNames(
                        "absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-full",
                        bgColor[ii]
                      )}
                    >
                      <div
                        className={classNames(
                          "svg-icon--fit-width h-full w-full flex-shrink-0 p-[20%]",
                          {
                            "text-white": isDark.includes(bgColor[ii]),
                            "text-black": !isDark.includes(bgColor[ii]),
                          }
                        )}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: solution?.icon?.[0]?.code,
                        }}
                      />
                      {/* separate shadow to prevent motion style interference */}
                      <div
                        className={classNames(
                          "absolute inset-0 h-full w-full rounded-full duration-500",
                          {
                            "shadow-md": hovered === ii,
                          }
                        )}
                      />
                    </m.div>
                  </AppLink>
                </li>
              );
            })}
          </ul>
          {/* background dots */}
          <div className="absolute inset-0 mx-auto aspect-square h-auto w-[75%] sm:w-full sm:max-w-[24rem] lg:max-w-none">
            <m.div
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: {
                  type: "tween",
                  duration: 0.5,
                },
              }}
              className="absolute inset-0 rounded-full border-4 border-dotted border-teal md:inset-3"
            >
              {Array(solutions.length)
                .fill()
                ?.map((s, i) => {
                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${s}${i}-line`}
                      style={{
                        transform: `rotate(${
                          (360 / solutions.length) * (i + 1)
                        }deg)`,
                      }}
                      className="absolute left-[50%] h-[50%] origin-bottom border-l-4 border-dotted border-teal"
                    />
                  );
                })}
            </m.div>
          </div>
        </div>
        {!!solutions?.length && (
          <div className="order-2 mt-5 flex flex-grow flex-col gap-5 md:mt-0 md:flex-1 lg:order-3">
            {solutions.slice(5, solutions.length).map((solution, i) => {
              return (
                <m.div
                  onHoverStart={() => setHovered(i + 5)}
                  onHoverEnd={() => setHovered(null)}
                  className="md:-mx-3"
                >
                  <SolutionChip
                    key={`${solution.uid}-chip`}
                    color={bgColor[i + 5]}
                    hovered={hovered}
                    order={i + 5}
                    {...solution}
                  />
                </m.div>
              );
            })}
          </div>
        )}
      </div>
    </Container>
  );
};

SolutionsWheel.defaultProps = {};

export default SolutionsWheel;
